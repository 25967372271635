import Carousel from 'better-react-carousel'
import ReactPlayer from 'react-player'
// https://github.com/devpanther/better-react-carousel
// https://github.com/cookpete/react-player

const Gallery = () => {
  const my_videos = [
    {
      video: process.env.PUBLIC_URL + "/video/mf-carro-casa.mp4",
      label: "Controle sua casa de qualquer lugar, a partir do seu automóvel"
    },
    {
      video: process.env.PUBLIC_URL + "/video/mf-cenas.mp4",
      label: "Cenas predefinidas com as cores de sua preferência"
    },
    {
      video: process.env.PUBLIC_URL + "/video/mf-firetv-adesivo.mp4",
      label: "Reproduza fácil seu programa de TV favorito"
    },
    {
      video: process.env.PUBLIC_URL + "/video/mf-cubo.mp4",
      label: "Cubo mágico, o controle remoto mais legal que você já viu"
    },
    {
      video: process.env.PUBLIC_URL + "/video/mf-alexa-zap.mp4",
      label: "Peça a Alexa para enviar whatsapp por você"
    }
  ]

  const rows = Math.ceil(my_videos.length / 2)

  return (
    <>
    <Carousel cols={2} rows={rows} gap={10}>
      {
        my_videos.map( e => {
          return (
            <Carousel.Item key={e.video}>
              <div className="fbox-content center"><h3>{e.label}</h3></div>
              <ReactPlayer 
                className='react-player'
                url={e.video}
                controls={true}
                width="100%"
              />
      </Carousel.Item>
          )
        })
      }
    </Carousel>
    </>
  )
}

const Automacoes = () => (
  <div>
    <section id="automacoes" className="pt-3 p-5">
      <div className="fancy-title title-bottom-border">
			  <h3>Automações</h3>
      </div>

      <Gallery/>

    </section>
  </div>
)

export default Automacoes
