// import { Link } from 'react-router-dom'
// import '../../../css/Institutional/Faq/index.css'
import ReactPlayer from 'react-player'

const Slider = () => (
  <div id="slider">
    <section className="slider-element dark center">

<video 
  autoplay
  muted
  loop
  id="myVideo"
  poster={process.env.PUBLIC_URL + "/video/video-background.jpg"}
>
  <source src={process.env.PUBLIC_URL + "/video/casa03.mp4"} type="video/mp4" />
</video>
      <div className="container z-1 slider-content justify-content-center align-items-start d-flex" style={{"border": "0px solid green", "background-color": "rgba(133, 14, 53, 0.60)", "width": "100%", "margin": "0"}}>
        <div className="row align-items-center justify-content-center mt-5 d-block">
            <p className="op-07 text-white mb-3 text-uppercase ls2 text-smaller">O próximo nível em Automação Residencial</p>
            <h1 className="display-4 mb-5 text-white fw-medium">
              Chegou a hora da <span className="svg-underline nocolor">Casa dos Sonhos</span></h1>
          <br/>
          <div class="justify-content-center d-flex">
        <ReactPlayer 
          className="react-player z-999"
          url={process.env.PUBLIC_URL + '/video/mf-apresentacao.mp4'}
          light={process.env.PUBLIC_URL + '/video/mf-apresentacao.jpg'}
          controls={true}
          pip={true}
        />
            </div>
         </div>
      </div>

    </section>
    </div>
)

export default Slider
